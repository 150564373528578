@font-face {
    font-family: 'Lato-Black';
    src: url("../Lato/Lato-Black.ttf") format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Lato-Bold';
    src: url("../Lato/Lato-Bold.ttf") format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Lato-BoldItalic';
    src: url("../Lato/Lato-BlackItalic.ttf") format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Lato-LightItalic';
    src: url("../Lato/Lato-LightItalic.ttf") format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Lato-Light';
    src: url("../Lato/Lato-Light.ttf") format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Lato-Regular';
    src: url("../Lato/Lato-Regular.ttf") format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Lato-Thin';
    src: url("../Lato/Lato-Thin.ttf") format('truetype');
    font-style: normal;
}
