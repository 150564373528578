@import "assets/fonts/Lato/Lato.css";
@import "https://fonts.googleapis.com/css2?family=PT+Mono&display=swap";


// MAIN STYLE
html {
  //font-family: 'Lato-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

input,
textarea {
  outline: none;
  box-shadow: none!important;
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
}

.corporate-color {
  color: #ff6900;
}

// SPINNER
.chat-spinner {
  width: 48px;
  height: 48px;
  border: 3px solid #999;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.chat-spinner::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #ff6900 transparent;
}

// PRIME RESTYLE
.p-tooltip {
  line-height: 1.4;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.3rem 0.75rem!important;
}

.p-toast {
  z-index: 99999999!important;
}

.p-confirm-dialog,
.p-dynamic-dialog {
  .p-button {
    color: #ff6900!important;
    border-color: #ff6900!important;
    background-color: transparent!important;
    &:disabled {
      background: none!important;
      &:hover {
        background: none!important;
      }
    }
    &:hover {
      background-color: #ff69001a!important;
    }
  }
}

.p-dropdown-items-wrapper {
  max-height: 100%!important;
}

.p-dropdown-panel.p-component {
  .p-dropdown-items {
    .p-dropdown-item {
      padding-right: 30px!important;
      &.p-highlight {
        position: relative;
        color: rgba(0, 0, 0, 0.87)!important;
        background: transparent;
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
        &::after {
          position: absolute;
          display: block;
          content: "";
          top: 46%;
          right: 23px;
          border-width: 0 2px 2px 0;
          border-style: solid;
          border-color: #ffffff;
          box-sizing: content-box;
          height: 6px;
          margin: 0 auto;
          width: 2px;
          transform: rotate(45deg) translateY(-50%);
        }
        &::before {
          position: absolute;
          display: block;
          content: "";
          top: 50%;
          right: 14px;
          border: 2px solid;
          height: 12px;
          width: 12px;
          background: #000000;
          border-radius: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.p-menu.sidebar-menu {
  min-width: 210px;
  left: 220px!important;
  z-index: 9999999!important;
  .p-submenu-header {
  }
}

.p-dialog-title {
  color: rgba(0, 0, 0, 0.6);
}

.p-dialog-header-icons,
.p-dialog-header-icon {
  min-width: 2rem!important;
}

.first-gradient {
  .p-avatar {
    background: linear-gradient(90deg, rgba(255, 130, 0, 1) 0%, rgba(250, 70, 22, 1) 100%)!important;
  }
}

.second-gradient {
  .p-avatar {
    background: linear-gradient(90deg, rgba(0, 164, 153, 1) 0%, rgba(0, 149, 203, 1) 100%) !important;
  }
}

.third-gradient {
  .p-avatar {
    background: linear-gradient(90deg, rgba(165, 24, 144, 1) 0%, rgba(68, 0, 153, 1) 100%) !important;
  }
}

// ICON STYLE
.chat-icon {
  height: 20px;
  width: 20px;
  background-size: 20px;

  &.gpt-4o,
  &.gpt-4-turbo,
  &.gpt-4-32k,
  &.gpt-4 {
    background-image: url("./assets/images/icons/small/gpt-logo-blue-small.svg");
  }
  &.gpt-35-turbo-16k {
    background-image: url("./assets/images/icons/small/gpt-logo-green-small.svg");
  }
  &.claude-35-sonnet,
  &.claude-3-opus,
  &.claude-3-sonnet,
  &.claude-3-haiku {
    background-image: url("./assets/images/icons/small/claude-logo-small.svg");
  }
  &.gemini-15-pro {
    background-image: url("assets/images/icons/small/gemini-logo-small.svg");
  }
  &.palm-2 {
    background-image: url("./assets/images/icons/small/palm-logo-original-small.svg");
  }
  &.dall-e-2 {
    background-image: url("./assets/images/icons/small/gpt-logo-purple-small.svg");
  }
  &.dall-e-3 {
    background-image: url("./assets/images/icons/small/gpt-logo-purple-small.svg");
  }
  &.hr-assistant {
    background-image: url("./assets/images/icons/small/hr-assistant-logo-small.svg");
  }
  &.submarine-icon {
    background-image: url("./assets/images/icons/small/submarine-logo-small.svg");
  }
  &.rocket-icon {
    background-image: url("./assets/images/icons/small/rocket-logo-small.svg");
  }
  &.excavator-icon {
    background-image: url("./assets/images/icons/small/excavator-logo-small.svg");
  }
  &.plus-icon {
    height: 18px;
    width: 18px;
    background-size: 18px;
    background-image: url("./assets/images/icons/small/plus-icon-small.svg");
  }
  &.lock-icon {
    background-image: url("./assets/images/icons/small/lock-logo-small.svg");
  }
}

@media screen and (max-width: 991px) {
  .p-dialog .p-dialog-content {
    width: 100% !important;
  }
}

// fix weird menu focus - https://github.com/primefaces/primevue/issues/4481
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
  background: rgba(0, 0, 0, 0);
}

.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
  background: rgba(0, 0, 0, 0.04);
}
// end

// fix "Use code" button ugly violet tint on focus
.p-button.p-button-text:enabled:focus, .p-button.p-button-outlined:enabled:focus {
  background-color: transparent;
}
// end